import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './assets/logo_dev_2.png';

function App() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 1;
      setProgress(progressValue);
      if (progressValue >= 89) {
        clearInterval(interval);
      }
    }, 10); // 2000ms / 89 ≈ 22ms para cada incremento

    return () => clearInterval(interval); // Limpa o intervalo caso o componente seja desmontado
  }, []);

  return (
    <div className="container">
      <img src={logo} alt="Logo" className="loading-logo" width={60} />
      <h1>Nathan Amaral</h1>
      <h2>Desenvolvedor Web</h2>
      <div className="command-line">
        <span>Skills:</span>
        <p>PHP, JavaScript, React, React-Native, WordPress, Node.js, Laravel</p>
      </div>
      <div className="command-line">
        <span>Sobre mim:</span>
        <p>Olá! Eu sou o Nathan Amaral, um desenvolvedor web especializado em PHP, JavaScript, WordPress, Node.js e Laravel. Tenho uma paixão por criar soluções web eficientes e inovadoras.</p>
      </div>
      <div className="command-line">
        <span>Projetos:</span>
        <ul>
          <li><a href="https://financify.amaraldev.com/" target="_blank" rel="noopener noreferrer">[Financify]</a> - Domine suas finanças com o Financify: simplificando a gestão financeira para uma vida mais tranquila e próspera.</li>
          <li><a href="https://nathan5d.github.io/gerador-orcamento/" target="_blank" rel="noopener noreferrer">[Gerador de Orçamento Online]</a> - Uma ferramenta eficiente para criar orçamentos personalizados com facilidade, permitindo que você forneça propostas profissionais com detalhes precisos e formatação elegante.</li>
          <li><a href="https://nathan5d.github.io/Memorific/" target="_blank" rel="noopener noreferrer">[Memorific]</a> - Jogo da Memória</li>
          <li><a href="https://nathan5d.github.io/Memorific/velha" target="_blank" rel="noopener noreferrer">[Jogo da Velha]</a> - Jogo da Velha</li>
          <li>[...]</li>
        </ul>
      </div>
      <div className="command-line">
        <span>GitHub:</span>
        <ul>
          <li><a href="https://github.com/nathan5d/gerador-orcamento" target="_blank" rel="noopener noreferrer">[Gerador de Orçamento Online]</a></li>
          <li><a href="https://github.com/nathan5d/meuimc" target="_blank" rel="noopener noreferrer">[Meu IMC</a> - Calculadora de Índice de Massa Corporal]</li>
          <li><a href="https://github.com/nathan5d/Memorific" target="_blank" rel="noopener noreferrer">[Memorific]</a> - Jogo da Memoria</li>
          <li>[...]</li>
        </ul>
      </div>
      <div className='command-line'>
        <span>Em desenvolvimento:</span>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <p>{progress}%</p>
      </div>
      <div className="command-line">
        <span>Contato:</span>
        <p>Email: <a href="mailto:contato@amaraldev.com">contato@amaraldev.com</a></p>
      </div>
      <div className='command-line blinking-cursor'>
        <span>_</span>
      </div>
    </div>
  );
}

export default App;
